<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="7"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2
              v-if="budget.budgetPeriod"
              class="custom-header-title float-left pr-1 mb-0"
            >
              Budget {{ `${dateFormat(budget.budgetPeriod.start)} - ${dateFormat(budget.budgetPeriod.end)}` }}
            </h2>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-right mb-1"
        md="3"
        cols="5"
      >
        <b-button
          v-if="canViewThisAction('update', 'BudgetSetup')"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="mobile_create_button"
          :to="{ name: 'budgeting-budget-setup-edit', params: { id: $route.params.id } }"
        >
          <feather-icon
            icon="EditIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle">Edit</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-card
      class="mb-3 p-2 calllog-details serviceForm_details"
    >
      <b-table-simple
        borderless
        responsive
        class="particulars_of_deceased d-none d-md-block"
      >
        <b-tbody>
          <b-tr>
            <b-th width="230px">
              Cost Centre
            </b-th>
            <b-td>{{ budget.costCenter ? budget.costCenter.name : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Budgetary Period
            </b-th>
            <b-td>{{ budget.budgetPeriod ? budget.budgetPeriod.name : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Expense Category Type
            </b-th>
            <b-td>
              {{ budget.expenseCategoryType }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <b-table-simple
        borderless
        responsive
        class="particulars_of_deceased d-block d-md-none"
      >
        <b-tbody>
          <b-tr>
            <b-th width="230px">
              Cost Centre
            </b-th>
            <b-td>{{ budget.costCenter ? budget.costCenter.name : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Budgetary Period
            </b-th>
            <b-td>{{ budget.budgetPeriod ? budget.budgetPeriod.name : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Expense Category Type
            </b-th>
            <b-td>
              {{ budget.expenseCategoryType }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <b-card
      no-body
      class="mb-3 p-2"
    >
      <b-table
        ref="refAllBudgetItemsTable"
        class="position-relative has_padding event_list"
        :items="budgetItems"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(allocated)="data">
          <span class="">{{ numberFormat(data.item.allocated) }}</span>
        </template>
        <template #cell(code)="data">
          <span class="">{{ data.item.code || '-' }}</span>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BTableSimple, BTable, BTbody, BTr, BTh, BTd,
  VBToggle, VBTooltip,
} from 'bootstrap-vue'
import { required } from '@validations'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import Ripple from 'vue-ripple-directive'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { canViewThisAction } = useAclUtils()
export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BTable,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  data() {
    return {
      canViewThisAction,
      budget: {},
      budgetItems: [],
      sortBy: '_id',
      sortDesc: false,
      isSortDirDesc: false,
      tableColumns: [
        {
          key: 'expenseCategory.stringID',
          label: 'id',
          sortable: true,
          tdClass: 'text-bold-black',
        },
        {
          key: 'code',
          label: 'budget code',
          sortable: false,
        },
        {
          key: 'expenseCategoryName',
          label: 'expense category',
          sortable: false,
        },
        {
          key: 'allocated',
          label: 'annual budget amount (S$)',
          sortable: false,
        },
      ],
      required,
    }
  },
  beforeMount() {
    this.$http.get(`budget/budget/${this.$route.params.id}/detail`)
      .then(response => {
        const { budget, budgetItems } = response.data
        this.budget = budget
        this.budgetItems = budgetItems
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {

  },
}
</script>
<style scoped>
</style>
